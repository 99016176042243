import { useEffect, useState } from "react";
import { MENU_API } from "./constants";

const useRestaurantMenu = (id) =>{
    const [restaurantDetail, setRestaurantDetail] = useState(null);
    
    useEffect(()=>{
        fetchData();
    },[])

    const fetchData= async()=>{
        const resData = await fetch(MENU_API+id);
        const json = await resData.json();
        setRestaurantDetail(json?.data);
    }
    return restaurantDetail;
}

export default useRestaurantMenu;