import RestaurantCard from "./RestaurantCard";
import { useState, useEffect, useContext } from "react";
import Shimmer from "./Shimmer";
import { Link } from "react-router-dom";
import useOnlineStatus from "../utils/useOnlineStatus";
import { RES_API } from "../utils/constants";
import { promotedRestaurant } from "./RestaurantCard";
import UserContext from "../utils/UserContext";
const Body = () => {
    const {loggedInUser, setUserName} = useContext(UserContext);
    const [ listOfRestautant, setListOfRestaurant ] = useState([]);
    const [ listOfFilteredRestaurant, setListOfFilteredRestaurant ] = useState([]);
    const [ searchText, setSearchText] = useState("");
    const status = useOnlineStatus();
    const PromotedRestaurantCard = promotedRestaurant(RestaurantCard);

    useEffect(()=>{
        fetchData();
    },[])

    const fetchData = async() =>{
        const data = await fetch(RES_API);
        const json = await data.json();
        setListOfRestaurant(json?.data?.cards[1].card?.card?.gridElements?.infoWithStyle?.restaurants);
        setListOfFilteredRestaurant(json?.data?.cards[1].card?.card?.gridElements?.infoWithStyle?.restaurants);
    } 

    if (status === false){
        return (<h1>Something Went Wrong!! Please Check Your Internet Connection!!</h1>);
    }

    return listOfRestautant.length == 0 ? (
        <Shimmer/>
    ):(
        <div>
            <div className="flex justify-center my-[20px]">
                <input 
                    type="text" 
                    autoComplete="on"
                    spellCheck="true"
                    autoCorrect="on"
                    value={searchText}
                    className="w-[600px] h-[50px] rounded-[10px] border pl-1 hover:bg-slate-50"
                    onChange={(e)=>{
                        setSearchText(e.target.value);
                    }}
                    placeholder={"Search For Your Favorite Food / Restaurant"}
                />
                <button
                onClick={()=>{
                    const filteredList = listOfRestautant.filter((restaurant) => {
                        return restaurant?.info?.name.toLowerCase().includes(searchText.toLowerCase());
                    });
                    setListOfFilteredRestaurant(filteredList);
                }}
                className="w-[122px] rounded-[10px] border bg-white hover:bg-slate-50"
                >
                    Search
                </button>
                <button 
                    className="w-[246] rounded-[10px] border  bg-white hover:bg-slate-50"
                    onClick={()=>{
                        const filteredList = listOfRestautant.filter((restaurant) => restaurant?.info?.avgRating > 4.5);
                        setListOfFilteredRestaurant(filteredList);
                        }} //onClick event and other events take a callback function as an paramter
                >
                    Top Rated Restaurant
                </button>
                <input 
                    type="text"
                    className="w-[600px] h-[50px] rounded-[10px] border pl-1 hover:bg-slate-50"
                    value={loggedInUser}
                    onChange={(e)=>{setUserName(e.target.value)}}
                >

                </input>
            </div>
            <div className="flex flex-wrap justify-center">{
                listOfFilteredRestaurant.map(restaurant => (
                   <Link className="no-underline" key={restaurant?.info?.id} to={"/restaurant/"+restaurant?.info?.id} >
                        { restaurant?.info?.isOpen ? <PromotedRestaurantCard resList = {restaurant}/>:<RestaurantCard  resList = {restaurant}/>}
                    </Link> 
                ))
            }                
            </div>
        </div>
    )
}

export default Body;