import { useDispatch } from "react-redux";
import { addItem } from "../utils/cartSlice";
import { CDN_URL } from "../utils/constants";
const RestaurantCardItems = ({ cardItems }) => {
    const dispatch = useDispatch();
    const handleAddItem = (item) =>{
        console.log('item',item);
        dispatch(addItem(item));
    }
    return cardItems.map((item) => (
        <>
            <div className="flex justify-between">
                <div className="w-5/6 mx-[10px] flex py-[20px] flex-col items-start">
                    <label className="font-bold">{item?.card?.info?.name}</label>
                    <label className="font-semibold">
                        ₹{item?.card?.info?.price ? item?.card?.info?.price / 100 : item?.card?.info?.defaultPrice / 100}
                    </label>
                    <label className="text-sm text-start my-3">{item?.card?.info?.description}</label>
                </div>
                <div className="relative py-[20px] w-1/6 flex flex-col">
                    <img className="w-full  rounded" src={CDN_URL + item?.card?.info?.imageId} />
                    <button 
                        className="w-[80px] py-1 px-2 absolute bottom-[40px] left-[40px] bg-black text-white rounded"
                        onClick={()=> handleAddItem(item)} 
                        >Add +</button>
                </div>
            </div>
            <div className="w-full h-[1px] bg-black"/>
        </>

    ));
};

export default RestaurantCardItems;
