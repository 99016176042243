import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: 'cart',
    initialState : {
        items:[]
    },
    reducers:{
        addItem : (state, action) =>{
            //We are mutating the state i.e updating the state
            state.items.push(action.payload);
        },
        removeItem : (state, action) =>{
            state.items.pop();
        },
        clearCart : (state,actions)=>{
            state.items.length = 0;
        }
    }
});

console.log('cartSlice',cartSlice); 

export const { addItem, removeItem, clearCart} = cartSlice.actions;
export default cartSlice.reducer;