import { Component } from "react";
import User from "./User";
import UserClass from "./UserClass";
import UserContext from "../utils/UserContext";

class About extends Component{
    constructor(props){
        super(props);
        console.log("Parent Constructor Called");   
    }

    componentDidMount(){
        console.log("Parent DidMount Called")
    }

    render(){
        console.log("Parent Render Called");
        return(
            <>
                <h1>
                    This Is an About Us Page !!
                </h1>
                <UserContext.Consumer>
                    {
                        (data) => <h1>{data.loggedInUser}</h1>
                    }
                </UserContext.Consumer>
                <UserClass name={"First Child"} age={"27"} location={"Mumbai"}/>
            </>
        
            )
    }

}
export default About;