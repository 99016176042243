import RestaurantCardItems from "./RestaurantCardItems";

const RestaurantCategory = ({ categoryData, showItems, setShowIndex }) => {
    const { title, itemCards } = categoryData?.card?.card;
    const handleClick = () =>{
      setShowIndex();
    }
    return (
        <div className="flex flex-col  mx-auto justify-center">
            <div 
              className="flex bg-tomato justify-between border-[2px] rounded-lg font-bold"
              onClick={()=> handleClick()}
            >
                <label className="m-[10px]">
                    {title} ({itemCards.length})
                </label>
                <label className="m-[10px]">🔽</label>
            </div>
            {showItems && <RestaurantCardItems cardItems={itemCards} />}
            <div className="h=[10px] flex justify-between border-[2px] my-[10px] rounded-lg"> </div>
        </div>
    );
};

export default RestaurantCategory;
