import React from "react"

class UserClass extends React.Component{
    constructor(props){
        super(props);
        this.state ={}
        console.log(this.props.name+"Constructor Called");
    }

    componentDidMount(){
        console.log(this.props.name+" DidMount Called")
    }

    render(){
        const {name, age,location} = this.props;
        console.log(this.props.name+"Render Called")
        return(
            <>
                <h2>Name:{name}</h2>
                <h2>Age:{age}</h2>
                <h2>Location:{location}</h2>
            </>)
    }
}

export default UserClass