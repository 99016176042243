import { useContext, useState } from "react";
import LogoImage from '../assets/SumitFoodCornerLogo.png';
import LoginImage from '../assets/login.png';
import LogOutImage from '../assets/logout.png';
import { Link } from "react-router-dom";
import useOnlineStatus from "../utils/useOnlineStatus";
import UserContext from "../utils/UserContext";
import { useSelector } from "react-redux";

const Header = () => {
    const [status, setStatus ]= useState(LoginImage);
    const userData = useContext(UserContext);
    const onlineStatus =  useOnlineStatus();

    //Subscribing to our store using selector
    const cartItems = useSelector((store) => store.cart.items);
    console.log('cartItems',cartItems);
    return (
        <>
            <marquee onClick={()=>stop()} behavior="scroll" direction="left" hspace="10" scrollamount="5"> <pre>Flat 10% Discount On Order Above 200₹!! 🎆                Flat 15% Discount On Order Above 300₹!!🎆                Flat 20% Discount On Order Above 500₹!!🎆                </pre></marquee>
            <div className="flex justify-between bg-tomato shadow-custom">
                <div className="logo">
                    <Link to="/"><img className="w-24 m-2.5" src={LogoImage}/></Link>
                </div>
                <div className="nav-items">
                    <ul className="flex hover:cursor-pointer">
                        <li className="m-5 list-none text-[32px] text-[#333333]">Status :{onlineStatus ? "✅" : "🔴"}</li>
                        <li className="m-5 list-none text-[32px] text-[#333333]"><Link className="link-class" to="/">Home</Link></li>
                        <li className="m-5 list-none text-[32px] text-[#333333]"><Link className="link-class"to="/about">About Us</Link></li>
                        <li className="m-5 list-none text-[32px] text-[#333333]"><Link className="link-class"to="/contact">Contact Us</Link></li>
                        <li className="m-5 list-none text-[32px] text-[#333333]"><Link className="link-class"to="/grocerry">Grocerry</Link></li>
                        <li className="m-5 list-none text-[32px] text-[#333333]"><Link className="link-class"to="/cart">Cart({cartItems.length} Items)</Link></li>
                        <li className="m-5 list-none text-[32px] text-[#333333]">{userData.loggedInUser}</li>
                        <img 
                            className="mt-[7px] w-[57px] h-[66px] mr-[15px]"
                            src={status}
                            onClick={()=>{
                            status==LoginImage ? setStatus(LogOutImage) : setStatus(LoginImage);
                        }}
                        />
                    </ul>
                </div>
        </div>
        </>
       
    )
}

export default Header;