const User = (props) =>{
    const {name, age, location} = props;
    return(
    <>
        <h2>Name:{name}</h2>
        <h2>Age:{age}</h2>
        <h2>Location:{location}</h2>
    </>
    )
}

export default User;