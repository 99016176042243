import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactDOM  from "react-dom/client"; 
import Body from "./src/components/Body";
import Header from "./src/components/Header";
import About from "./src/components/About";
import Contact from "./src/components/Contact";
import Error from "./src/components/Error";
import { createBrowserRouter, RouterProvider, Outlet} from "react-router-dom";
import Restaurant from "./src/components/Restaurant";
import UserContext from "./src/utils/UserContext";
import { Provider } from "react-redux";
import appStore from "./src/utils/appStore";
import Cart from "./src/components/Cart";

/**
 * -App
 *  -Header
 *      -Logo
 *      -Navitems
 *  -Body
 *      -Search bar
 *      -Restaurant container
 *          - Restaurant card
 *  -Footer 
 *      -Copyright
 *      -Links
 *      -Contact
 * 
 */

const Grocerry = lazy(() => import("./src/components/Grocery"))

const AppLayout = () => {
    const [userName, setUserName] = useState();

    useEffect(()=>{
        const data = {
            name : "Sumit Negi"
        }
        setUserName(data.name);
    },[])

    return (
        <Provider store={appStore}>
            <UserContext.Provider value={{loggedInUser : userName, setUserName} }>
            <div className="bg-body min-h-screen">
                <UserContext.Provider value={{loggedInUser : userName}}>
                    <Header/>
                </UserContext.Provider>
                <Outlet/>
            </div>
        </UserContext.Provider>
        </Provider>
        
    )
}

const appRouter = createBrowserRouter([
    {
        path: "/",
        element: <AppLayout/>,
        errorElement:<Error/>,
        children:[
            {
                path:"/",
                element:<Body/>
            },
            {
                path:"/about",
                element:<About/>
            },
            {
                path:"/contact",
                element:<Contact/>
            },
            {
                path:"/grocerry",
                element:<Suspense fallback={<h1>Loading..</h1>} ><Grocerry/></Suspense>
            },{
                path:"/restaurant/:id",
                element:<Restaurant/>
            },
            {
                path:"/Cart",
                element:<Cart/>
            }
        ]
    }
])
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter}/>);  // render converts the object into html code