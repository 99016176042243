import { useState } from "react";
import { useParams } from "react-router-dom";
import useRestaurantMenu from "../utils/useRestaurantMenu";
import RestaurantCategory from "./RestaurantCategory";

const Restaurant = () =>{
    const {id} = useParams();
    const [showIndex, setShowIndex] = useState();
    const restaurantDetail = useRestaurantMenu(id);
    if(restaurantDetail == null){
        return <></>;
    }  
    const RestaurantCards  = restaurantDetail?.cards[4]?.groupedCard?.cardGroupMap?.REGULAR?.cards;
    const {name , costForTwoMessage, avgRatingString, totalRatingsString, cuisines, areaName, sla} = restaurantDetail?.cards[2]?.card?.card?.info;
    const itemCards = RestaurantCards.filter((c) => c?.card?.card?.['@type'] == "type.googleapis.com/swiggy.presentation.food.v2.ItemCategory");

    return( 
    <div className="text-center w-6/12 mx-auto">
        <h1 className="font-bold my-6 text-2xl">{name}</h1>
        <h3 className="font-bold text-xl">{avgRatingString}⭐({totalRatingsString})</h3>
        <h3 className="font-bold text-lg">{costForTwoMessage}</h3>
        <h4 className="font-bold text-base">{cuisines.join(", ")}</h4>
        <h4 className="font-bold text-sm">Outlet: {areaName}</h4>
        <h4 className="font-bold text-sm">{sla?.slaString}</h4>
        {
            itemCards.map((details, index)=>(
                <RestaurantCategory 
                    key={details?.card?.card?.title} 
                    categoryData = {details} 
                    showItems={index == showIndex && true} 
                    setShowIndex={() => setShowIndex(index)}
                />
            ))
        }
    </div>

    )
    
}
export default Restaurant;