import { useContext } from "react";
import { CDN_URL } from "../utils/constants";
import UserContext from "../utils/UserContext";


const RestaurantCard = (props) => {
    const { resList } = props;
    const cardDetails = resList?.info;
    const cuisines = cardDetails?.cuisines;
    const userName = useContext(UserContext);

    return (
        <div className="bg-[#FFFFF0] shadow-custom1 transition-transform duration-300 ease-in-out w-[240px] h-[500px] p-[5px] mx-[10px] my-[15px] rounded-[10px] overflow-hidden transform hover:scale-110 cursor-pointer">
            <div className="flex justify-center w-full h-[300px] overflow-hidden transition-transform duration-300 ease-in-out transform hover:scale-110">
                <img
                    alt="res-logo"
                    className="w-full h-auto transition-transform duration-300 ease-in"
                    src={CDN_URL + cardDetails?.cloudinaryImageId}
                />
            </div>
            <div className="card-details">
                <h3 className="font-extrabold my-3">{cardDetails?.name}</h3>
                <h4 className="font-semibold my-3">
                    {cuisines ? cuisines.slice(0, 5).join(", ") + (cuisines.length > 5 ? ", ...more" : "") : ""}
                </h4>
                <h4 className="font-semibold my-3">{cardDetails?.costForTwo}</h4>
                <h4 className="font-semibold my-3">{cardDetails?.avgRating}⭐</h4>
                <h4 className="font-semibold my-3">{userName.loggedInUser}</h4>
            </div>
        </div>
    );
};

export const promotedRestaurant = (RestaurantCard) => {
    return (props) => {
        return (
            <>
                <label className="absolute z-50 mx-[10px] my-[15px] px-[5px] bg-tomato text-black rounded-lg">
                    Promoted
                </label>
                <RestaurantCard {...props} />
            </>
        );
    };
};
export default RestaurantCard;
